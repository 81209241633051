<template>
  <div>
    <v-btn @click="$router.push({ path: '/' })" icon>
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <base-overview-card title="Payments" model="payments">
      <payments-table :course-id="courseId" />
      <v-container>
        <v-form
          ref="form"
          v-model="isFormValid"
          @submit.prevent="handleSavePayments"
        >
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="invoiceNumber"
                :rules="notEmptyRule"
                label="Invoice No."
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                v-model="invoiceAmount"
                :rules="notEmptyRule"
                label="Amount"
                type="number"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-file-input
                v-model="invoiceImage"
                accept="image/*"
                label="Invoice Image"
              ></v-file-input>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn
                type="submit"
                :disabled="!isFormValid"
                class="mt-4 ml-4 white--text"
                icon
                @click="loader = 'loading5'"
              >
                <v-icon dark> mdi-send </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </base-overview-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    BaseOverviewCard: () => import('@/components/BaseOverviewCard'),
    PaymentsTable: () => import('@/components/PaymentsTable'),
  },

  props: {
    courseId: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    isFormValid: false,
    invoiceNumber: '',
    invoiceAmount: '',
    invoiceImage: null,
    notEmptyRule: [(v) => !!v || 'Field is required'],
  }),

  computed: {
    ...mapState('payments', ['payments']),
  },

  created() {
    this.bindPaymentsRef();
  },

  methods: {
    ...mapActions('payments', [
      'bindPaymentsRef',
      'savePayment',
      'uploadPaymentImage',
    ]),
    async handleSavePayments() {
      const paymentRequest = {
        courseId: this.courseId,
        invoiceNumber: this.invoiceNumber,
        invoiceAmount: this.invoiceAmount,
      };
      if (this.invoiceImage) {
        const uploadedFilename = await this.uploadPaymentImage({
          file: this.invoiceImage,
          courseId: this.courseId,
        });
        paymentRequest.paymentFile = uploadedFilename;
      }
      this.savePayment(paymentRequest);
    },
  },
};
</script>
